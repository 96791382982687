import React from 'react'
import PropTypes from 'prop-types'

import Microsoft from '../hooks-custom/Microsoft'

export const MapContext = React.createContext(null)

const Map = React.forwardRef(({
  allowHidingLabelsOfRoad,
  allowInfoboxOverflow,
  backgroundColor,
  bounds,
  center,
  centerOffset,
  credentials,
  customMapStyle,
  disableBirdseye,
  disableKeyboardInput,
  disableMapTypeSelectorMouseOver,
  disablePanning,
  disableScrollWheelZoom,
  disableStreetside,
  disableStreetsideAutoCoverage,
  disableZooming,
  enableClickableLogo,
  enableCORS,
  enableHighDpi,
  enableInertia,
  heading,
  labelOverlay,
  liteMode,
  mapTypeId,
  maxBounds,
  maxZoom,
  minZoom,
  navigationBarMode,
  navigationBarOrientation,
  padding,
  pitch,
  showBreadcrumb,
  showDashboard,
  showLocateMeButton,
  showMapTypeSelector,
  showScalebar,
  showTrafficButton,
  showTermsLink,
  showZoomButtons,
  streetsideOptions,
  supportedMapTypes,
  zoom,
  onClick,
  onClickThrottleInterval,
  onDoubleClick,
  onDoubleClickThrottleInterval,
  onMapResize,
  onMapResizeThrottleInterval,
  onMapTypeChanged,
  onMapTypeChangedThrottleInterval,
  onMouseDown,
  onMouseDownThrottleInterval,
  onMouseOut,
  onMouseOutThrottleInterval,
  onMouseOver,
  onMouseOverThrottleInterval,
  onMouseUp,
  onMouseUpThrottleInterval,
  onMouseWheel,
  onMouseWheelThrottleInterval,
  onRightClick,
  onRightClickThrottleInterval,
  onViewChange,
  onViewChangeThrottleInterval,
  onViewChangeEnd,
  onViewChangeEndThrottleInterval,
  onViewChangeStart,
  onViewChangeStartThrottleInterval,
  children,
}, ref) => {
  const [map, divRef] = Microsoft.Maps.Map.useConstructor({
    allowHidingLabelsOfRoad,
    allowInfoboxOverflow,
    backgroundColor,
    bounds,
    center,
    centerOffset,
    credentials,
    customMapStyle,
    disableBirdseye,
    disableKeyboardInput,
    disableMapTypeSelectorMouseOver,
    disablePanning,
    disableScrollWheelZoom,
    disableStreetside,
    disableStreetsideAutoCoverage,
    disableZooming,
    enableClickableLogo,
    enableCORS,
    enableHighDpi,
    enableInertia,
    heading,
    labelOverlay,
    liteMode,
    mapTypeId,
    maxBounds,
    maxZoom,
    minZoom,
    navigationBarMode,
    navigationBarOrientation,
    padding,
    pitch,
    showBreadcrumb,
    showDashboard,
    showLocateMeButton,
    showMapTypeSelector,
    showScalebar,
    showTrafficButton,
    showTermsLink,
    showZoomButtons,
    streetsideOptions,
    supportedMapTypes,
    zoom,
  });

  React.useImperativeHandle(ref, () => map, [
    map,
  ]);

  Microsoft.Maps.Events.useAddHandler(map, 'click', onClick, onClickThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'dblclick', onDoubleClick, onDoubleClickThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'mapresize', onMapResize, onMapResizeThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'maptypechanged', onMapTypeChanged, onMapTypeChangedThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'mousedown', onMouseDown, onMouseDownThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'mouseout', onMouseOut, onMouseOutThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'mouseover', onMouseOver, onMouseOverThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'mouseup', onMouseUp, onMouseUpThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'mousewheel', onMouseWheel, onMouseWheelThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'rightclick', onRightClick, onRightClickThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'viewchange', onViewChange, onViewChangeThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'viewchangeend', onViewChangeEnd, onViewChangeEndThrottleInterval);
  Microsoft.Maps.Events.useAddHandler(map, 'viewchangestart', onViewChangeStart, onViewChangeStartThrottleInterval);

  return (
    <div ref={divRef}>
      <MapContext.Provider value={map}>{children}</MapContext.Provider>
    </div>
  );
})

Map.propTypes = {
  allowHidingLabelsOfRoad: PropTypes.bool,
  allowInfoboxOverflow: PropTypes.bool,
  backgroundColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      a: PropTypes.number.isRequired,
      r: PropTypes.number.isRequired,
      g: PropTypes.number.isRequired,
      b: PropTypes.number.isRequired,
    }),
  ]),
  bounds: PropTypes.shape({
    center: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  center: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }),
  centerOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  credentials: PropTypes.string,
  customMapStyle: PropTypes.object,
  disableBirdseye: PropTypes.bool,
  disableKeyboardInput: PropTypes.bool,
  disableMapTypeSelectorMouseOver: PropTypes.bool,
  disablePanning: PropTypes.bool,
  disableScrollWheelZoom: PropTypes.bool,
  disableStreetside: PropTypes.bool,
  disableStreetsideAutoCoverage: PropTypes.bool,
  disableZooming: PropTypes.bool,
  enableClickableLogo: PropTypes.bool,
  enableCORS: PropTypes.bool,
  enableHighDpi: PropTypes.bool,
  enableInertia: PropTypes.bool,
  heading: PropTypes.number,
  labelOverlay: PropTypes.oneOf(['hidden', 'visible']),
  liteMode: PropTypes.bool,
  mapTypeId: PropTypes.oneOf(['aerial', 'birdseye', 'canvasDark', 'canvasLight', 'grayscale', 'mercator', 'ordnanceSurvey', 'road', 'streetside']),
  maxBounds: PropTypes.shape({
    center: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  navigationBarMode: PropTypes.oneOf(['compact', 'default', 'minified', 'square']),
  navigationBarOrientation: PropTypes.oneOf(['horizontal', 'vertical']),
  padding: PropTypes.number,
  pitch: PropTypes.number,
  showBreadcrumb: PropTypes.bool,
  showDashboard: PropTypes.bool,
  showLocateMeButton: PropTypes.bool,
  showMapTypeSelector: PropTypes.bool,
  showScalebar: PropTypes.bool,
  showTrafficButton: PropTypes.bool,
  showTermsLink: PropTypes.bool,
  showZoomButtons: PropTypes.bool,
  streetsideOptions: PropTypes.exact({
    disablePanoramaNavigation: PropTypes.bool,
    locationToLookAt: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }),
    onErrorLoading: PropTypes.func,
    onSuccessLoading: PropTypes.func,
    overviewMapMode: PropTypes.oneOf(['expanded', 'hidden', 'minimized']),
    panoramaInfo: PropTypes.exact({
      cd: PropTypes.string,
    }),
    panoramaLookupRadius: PropTypes.number,
    showCurrentAddress: PropTypes.bool,
    showExitButton: PropTypes.bool,
    showHeadingCompass: PropTypes.bool,
    showProblemReporting: PropTypes.bool,
    showZoomButtons: PropTypes.bool,
  }),
  supportedMapTypes: PropTypes.arrayOf(PropTypes.oneOf(['aerial', 'birdseye', 'canvasDark', 'canvasLight', 'grayscale', 'mercator', 'ordnanceSurvey', 'road', 'streetside'])),
  zoom: PropTypes.number,
  onClick: PropTypes.func,
  onClickThrottleInterval: PropTypes.number,
  onDoubleClick: PropTypes.func,
  onDoubleClickThrottleInterval: PropTypes.number,
  onMapResize: PropTypes.func,
  onMapResizeThrottleInterval: PropTypes.number,
  onMapTypeChanged: PropTypes.func,
  onMapTypeChangedThrottleInterval: PropTypes.number,
  onMouseDown: PropTypes.func,
  onMouseDownThrottleInterval: PropTypes.number,
  onMouseOut: PropTypes.func,
  onMouseOutThrottleInterval: PropTypes.number,
  onMouseOver: PropTypes.func,
  onMouseOverThrottleInterval: PropTypes.number,
  onMouseUp: PropTypes.func,
  onMouseUpThrottleInterval: PropTypes.number,
  onMouseWheel: PropTypes.func,
  onMouseWheelThrottleInterval: PropTypes.number,
  onRightClick: PropTypes.func,
  onRightClickThrottleInterval: PropTypes.number,
  onViewChange: PropTypes.func,
  onViewChangeThrottleInterval: PropTypes.number,
  onViewChangeEnd: PropTypes.func,
  onViewChangeEndThrottleInterval: PropTypes.number,
  onViewChangeStart: PropTypes.func,
  onViewChangeStartThrottleInterval: PropTypes.number,
}

Map.defaultProps = {
  allowHidingLabelsOfRoad: undefined,
  allowInfoboxOverflow: undefined,
  backgroundColor: undefined,
  bounds: undefined,
  center: undefined,
  centerOffset: undefined,
  credentials: undefined,
  customMapStyle: undefined,
  disableBirdseye: undefined,
  disableKeyboardInput: undefined,
  disableMapTypeSelectorMouseOver: undefined,
  disablePanning: undefined,
  disableScrollWheelZoom: undefined,
  disableStreetside: undefined,
  disableStreetsideAutoCoverage: undefined,
  disableZooming: undefined,
  enableClickableLogo: undefined,
  enableCORS: undefined,
  enableHighDpi: undefined,
  enableInertia: undefined,
  heading: undefined,
  labelOverlay: undefined,
  liteMode: undefined,
  mapTypeId: undefined,
  maxBounds: undefined,
  maxZoom: undefined,
  minZoom: undefined,
  navigationBarMode: undefined,
  navigationBarOrientation: undefined,
  padding: undefined,
  pitch: undefined,
  showBreadcrumb: undefined,
  showDashboard: undefined,
  showLocateMeButton: undefined,
  showMapTypeSelector: undefined,
  showScalebar: undefined,
  showTrafficButton: undefined,
  showTermsLink: undefined,
  showZoomButtons: undefined,
  streetsideOptions: undefined,
  supportedMapTypes: undefined,
  zoom: undefined,
  onClick: undefined,
  onClickThrottleInterval: undefined,
  onDoubleClick: undefined,
  onDoubleClickThrottleInterval: undefined,
  onMapResize: undefined,
  onMapResizeThrottleInterval: undefined,
  onMapTypeChanged: undefined,
  onMapTypeChangedThrottleInterval: undefined,
  onMouseDown: undefined,
  onMouseDownThrottleInterval: undefined,
  onMouseOut: undefined,
  onMouseOutThrottleInterval: undefined,
  onMouseOver: undefined,
  onMouseOverThrottleInterval: undefined,
  onMouseUp: undefined,
  onMouseUpThrottleInterval: undefined,
  onMouseWheel: undefined,
  onMouseWheelThrottleInterval: undefined,
  onRightClick: undefined,
  onRightClickThrottleInterval: undefined,
  onViewChange: undefined,
  onViewChangeThrottleInterval: undefined,
  onViewChangeEnd: undefined,
  onViewChangeEndThrottleInterval: undefined,
  onViewChangeStart: undefined,
  onViewChangeStartThrottleInterval: undefined,
}

export default React.memo(Map)
